@import "styles/variables";

.location-setup {
  display: flex;
  flex-grow: 1;
  flex-direction: row;

  main {
    display: flex;
    width: 40vw;
    justify-content: center;
    flex-direction: column;
    margin: 0 auto;

    @media (max-width: $tablet-responsive-width) {
      width: 90vw;
    }

    header {
      padding-top: 30px;
      text-align: center;

      .screen-title {
        line-height: 50px;
        background-color: $selection-color;
        border-radius: 5px;
      }
    }

    .actionBar {
      border-top: none;
      justify-content: center;
    }
  }
}
