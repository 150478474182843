@import "styles/variables";

.learn-more-modal {
  .modal-header {
    border-color: white;
    padding-block: 4px;
  }
  .header {
    background-color: white;
    font-family: $font-family-v2;
    font-size: 14px;
    font-weight: bold;
  }

  h5.modal-title {
    font-family: $font-family-v2;
    margin-top: 20px;
  }

  .modal-body {
    background-color: white;
    font-family: $font-family-v2-base;
    color: #65647f;
    font-size: 14px;
    padding-block: 4px;

    p {
      font-size: 1.2em;
      margin-bottom: 1.3rem;
    }

    .form-group {
      margin-bottom: 1.5rem;

      label {
        color: #65647f;
        font-size: 1.2em;
        font-weight: 500;
        margin-bottom: 0;
      }

      .form-control {
        font-family: $font-nimbus;
        font-size: 14px;
        border: 1px solid $v2-label;
        border-radius: 0.25rem;

        &.is-invalid {
          border-color: $brand-red;
        }

        &::placeholder {
          color: #adb5bd;
        }
      }

      .text-danger {
        font-size: 80%;
        padding-top: 0.25rem;
        color: $brand-red;
      }
    }
  }

  .modal-footer {
    background-color: white;
    display: flex;
    justify-content: center;
    padding: 22px;
    border-top: none;

    .submit-learn {
      font-family: $font-family-v2-base;
      font-size: 14px;
      padding: 0.375rem 0.75rem;
      width: 200px;
      border-radius: 0.25rem;
      font-weight: bold;

      &.btn-secondary {
        font-family: $font-family-v2-base;
        background-color: transparent;
        border: 1px solid $dark-gray;
        color: $dark-gray;
        text-transform: uppercase;

        &:hover {
          text-decoration: underline;
        }
      }

      &.btn-primary {
        font-family: $font-family-v2-base;
        background-color: $dark-gray;
        border: 1px solid $dark-gray;
        color: white;
        text-transform: uppercase;

        &:hover {
          background-color: darken($dark-gray, 10%);
          border-color: darken($dark-gray, 10%);
          box-shadow: 0 0 0 2px rgba($dark-gray, 0.5);
        }

        &:focus {
          box-shadow: 0 0 0 2px rgba($dark-gray, 0.5);
          outline: 0;
        }

        &:disabled {
          background-color: lighten($dark-gray, 40%);
          border-color: lighten($dark-gray, 40%);
        }
      }
    }
  }

  .horizontal-rule {
    width: 85%;
    margin: 1rem auto;
    border-top: 1px solid $v2-label;
  }
}

@media (max-width: $tablet-responsive-width) {
  .learn-more-modal {
    .header {
      font-size: 1.2rem;
    }

    .modal-body {
      p {
        font-size: 0.9rem;
      }

      .form-group {
        label {
          font-size: 12px;
        }

        .form-control {
          font-family: $font-nimbus;
          font-size: 12px;
        }

        .text-danger {
          font-size: 70%;
        }
      }
    }

    .modal-footer {
      .btn {
        font-size: 0.875rem;
        padding: 0.375rem 0.75rem;
        width: 120px;
      }
    }
  }
}
