@import "styles/variables";

.billingEstimateTableMobile {
  h6 {
    margin-right: 5px;
    display: inline-block;
  }

  .item {
    border-bottom: 1px solid $ghosts-whisper-gray;
    margin-bottom: 20px;
  }
}

@media (min-width: 600px) {
  .billingEstimateTableMobile {
    display: none;
  }
}