@import "styles/variables";

.horizontal-rule {
  width: 100%;
  border-bottom: 1px solid $heavy-gray;

  &.dashed {
    $dash-width: 8px;
    $space-width: 8px;

    height: 1px;
    border: none;

    background-image: repeating-linear-gradient(
      to right,
      $heavy-gray,
      $heavy-gray $dash-width,
      transparent $dash-width,
      transparent ($dash-width + $space-width)
    );
  }

  &.white {
    border-bottom: 1px solid white;

    &.dashed {
      $dash-width: 8px;
      $space-width: 8px;

      height: 1px;
      border: none;

      background-image: repeating-linear-gradient(
        to right,
        white,
        white $dash-width,
        transparent $dash-width,
        transparent ($dash-width + $space-width)
      );
    }
  }
}

@media (max-width: $responsive-width) {
  .hidden-responsive {
    display: none;
  }
}
