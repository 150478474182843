@import "styles/variables";

.sectionBannerAlt {
  width: 100%;
  background-color: $blue-steel;
  padding: 15px;
  color: $light-teal;
  font-family: $secondaryFont;
  font-size: 30px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
  text-align: center;
}
