@import 'styles/variables';

.ant-menu {
  background-color: $navbar-bg-gray !important;
  text-transform: uppercase;
  font-family: $font-family-base !important;

  .ant-menu-item a {
    color: white !important;
    text-decoration: none !important;
  }

  .ant-menu-item-group-title {
    color: $light-teal !important
  }

  .ant-menu-item-selected {
    background-color: transparent !important;
    border-left: 8px solid $light-teal;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    color: $light-teal;
  }
}




