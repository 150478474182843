// Colors
$pretty-blue: #00b3ef;
$teal: #bbe5e6;
$dark-teal: #006e6d;
$brand-teal: #30827b;
$light-teal: #33cccc;
$lighter-teal: #eefafa;
$brand-red: #f33;
$alt-brand-red: #ec1c24;
$blood-red: #e03738;
$banana-yellow: #f8cd55;
$rust-orange: #fc363b90;
$dark-rust-orange: rgba(252, 54, 59, 0.75);
$lighter-gray: #d9d9d9;

// The Twilight Zone
$event-list-gray: #f3f4f5;
$barely-gray: #f2f4f3;
$light-gray: #e6e6e6;
$sidebar-gray: #e4e4e4;
$ghosts-whisper-gray: #dcdcdd;
$soft-gray: #c5c3c6;
$another-gray: #b9b6ba;
$text-gray: #aab8be;
$login-gray: #979797;
$blue-steel: #454e56;
$heavier-gray: #373636;
$heavy-gray: #78868d;
$medium-gray: #677379;
$wet-cement: #363d43;
$navbar-bg-gray: #464e55;
$transparent-btn-gray: #858b9a;
$shift-job-item-text-gray: #79968d;
$job-creation-sidebar: #eef3f5;
$job-creation-gray: #9c9bac;
$dark-gray: #55556d;
$dim-gray: #4d4d4d;
$big-money-green: #009245;
$lavender-blue: #cfd3e0;

// Font Declarations
@font-face {
  font-family: Evogria;
  src: url("Evogria.otf");
}

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf");
}

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf");
}

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic;
}

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf");
}

// Media query widths
$responsive-width: 1100px;
$nav-responsive-width: 1024px;
$tablet-responsive-width: 768px;
$home-responsive-width: 575px;
$about-us-responsive-width: 800px;
$phone-responsive-width: 420px;

/// Bootstrap Overrides ///

// Fonts
$sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif,
  "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";

$font-family-v2: Gotham, $sans-serif;
$font-family-v2-base: Gotham-Book, $sans-serif;
$font-family-base: "Lato", $sans-serif;
$secondaryFont: Evogria, $sans-serif;
$headings-font-family: $font-family-v2;
$font-nimbus: "nimbus-sans", $sans-serif;
$font-family-v2-medium: Gotham-Medium, $sans-serif;

// Theme
$primary: $light-teal;
$secondary: $heavy-gray;
$tertiary: $dark-teal;
$danger: $brand-red;

$border-radius: 0;
$navbar-height: 78px;

$v2-footer-height: 30px;
$footer-height: 180px;

// Text Colors
$link-color: $pretty-blue;
$dark-text-color: $heavier-gray;
$alert-color: $blood-red;

// Backgrounds
$body-bg: $barely-gray;
$login-bg: $wet-cement;
$navbar-background: $blue-steel;
$modal-background-color: rgba($primary, 0.1);
$selection-color: #fee02d;

// Form controls
$custom-file-bg: $primary;
$custom-file-color: white;
$custom-file-border-color: $primary;
$input-placeholder-color: $soft-gray;
$input-group-addon-bg: white;
$input-group-addon-color: $another-gray;
$input-border-color: $another-gray;
$btn-border-radius: 3px;

// V2 Navigation
$nav-label: #9d9dad;
$v2-label: #65647f;
$v3-label: #9d9cad;

/// Mixins ///
@mixin eventListColumnWidths() {
  flex-grow: 1;

  &:nth-of-type(1) {
    width: 7%;
  }

  &:nth-child(2) {
    width: 28%;
    flex-grow: 2;
    padding-right: 10px;
  }

  &:nth-child(3) {
    width: 14%;
  }

  &:nth-child(4) {
    width: calc((9%) + 1px);
    text-align: center;
  }

  &:nth-child(5) {
    width: calc(9%);
    text-align: center;
  }

  &:nth-child(6) {
    width: calc((9%) + 1px);
    text-align: center;
  }

  &:nth-child(7) {
    width: 14%;
    text-align: center;
  }

  &:nth-child(8) {
    width: 9%;
    text-align: center;
  }
}

@mixin financesListColumnWidths() {
  flex-grow: 1;

  &:nth-of-type(1) {
    width: 7%;
  }

  &:nth-of-type(2) {
    width: 30%;
  }

  &:nth-of-type(3) {
    width: 14%;
  }

  &:nth-of-type(4) {
    width: 14%;
  }

  &:nth-of-type(5) {
    width: 14%;
  }

  &:nth-of-type(6) {
    width: 14%;
  }

  &:nth-of-type(7) {
    width: 7%;
  }
}
