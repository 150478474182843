.pagination > li {
    display: inline-block;
    padding-left: 0;
  }
  .pagination > li {
    list-style: none;
    border: 0.9px solid;
  }
  .pagination > li > a,
  .pagination > li > span {
    position: relative;
    float: left;
    padding: 6px 12px;
    line-height: 1.42857143;
    text-decoration: none;
    color: #2c689c;
    background-color: #fff;
    border: 1px solid #ddd;
    margin-left: -1px;
  }
  
  .pagination>li.active>a {
    color: #fff;
    background-color: #464e55;
    border-color: #464e55;
  }
  
  /* Style the active class (and buttons on mouse-over) */
  .pagination > li > a:hover {
    background-color:  #464e55;
    color: white;
  }
  .pagination > li:first-child > a,
  .pagination > li:first-child > span {
    margin-left: 0;
    padding: 1px 10px 0px 10px!important;
    width:100%;
    height:100%;
    font-size: 20px;
    font-weight: 300;
  }
  .pagination > li:last-child > a,
  .pagination > li:last-child > span {
    margin-right: 0;
    padding: 1px 10px 0px 10px!important;
    width:100%;
    height:100%;
    font-size: 20px;
    font-weight: 300;
  }

@media (max-width: 1100px) {
  .pagination {
    overflow: scroll;
    justify-content: center;
  }
}